<template>
  <div :class="$style.page">
    <div :class="$style.wrapper">
      <el-button size="small" type="primary" @click="submitForm()">
        Создать
      </el-button>
    </div>
    <el-form
      :class="$style.form"
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="12rem"
    >
      <el-form-item label="Название" prop="question">
        <el-input v-model="form.question"></el-input>
      </el-form-item>

      <el-form-item label="Описание" prop="answer">
        <TextEditor
          :value.sync="form.answer"
          :is-image="false"
          :is-media="false"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import TextEditor from '@/components/atoms/TextEditor.vue'
import notification from '@/mixins/notifications'
import { ADDSELLERS_ADMIN_ROUTES } from '@/constants/routing'
import { PROJECTS } from '@/constants/projects'

export default {
  components: { TextEditor },
  mixins: [notification],
  data() {
    return {
      form: {
        question: '',
        answer: '',
      },
      rules: {
        question: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        answer: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
      },
    }
  },
  methods: {
    async submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })
          const data = {
            ...this.form,
            project: PROJECTS.ADDSELLERS,
          }
          const { error } =
            await delivery.ContentServiceCore.SeoFaqsActions.create(data)

          loading.close()

          if (error) {
            this.showNotification('Ошибка создания Seo FAQ', 'error')
            return
          }

          this.showNotification('Seo FAQ успешно создан', 'success')

          this.$router.push(ADDSELLERS_ADMIN_ROUTES.CONTENT.SEOFAQS.LIST)
        }
      })
    },
  },
}
</script>

<style lang="scss" module>
.page {
  padding: 1rem;
  .wrapper {
    @include stickyWrapper;
  }
  .form {
    & > div > label {
      text-align: left;
    }
  }
}
</style>
